var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("CommonTree", {
            attrs: {
              treeData: _vm.treeData,
              defaultProps: _vm.defaultProps,
              searchTitle: _vm.searchTitle,
              isShowdig: true,
              showCheckbox: false,
              "popover-show": true,
              expandOnClickNode: false,
              treeTitle: "知识库分类",
            },
            on: {
              "include-down": _vm.includeDown,
              getTreeAdd: _vm.treeMenuAdd,
              getTreeEdit: _vm.treeMenuEdit,
              getTreeDelete: _vm.treeNodeDel,
              getNodeClick: _vm.treeNodeClick,
            },
          }),
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-btn-options": _vm.headBtnOptions,
                  "head-title": "维度分类",
                },
                on: {
                  "head-remove": _vm.headRemove,
                  "head-add-tabs": _vm.headAdd,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayout",
                attrs: {
                  "grid-head-btn": _vm.gridHeadBtn,
                  "search-columns": _vm.searchColumns,
                },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                attrs: {
                  "grid-row-btn": _vm.gridRowBtn,
                  "table-options": _vm.tableOptions,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.crudLoading,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                },
                on: {
                  "grid-row-detail-click": _vm.rowView,
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "page-refresh-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                  "row-remove": _vm.rowRemove,
                  "row-edit": _vm.rowEdit,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showTreeDialog
        ? _c(
            "common-dialog",
            {
              staticClass: "qmDialog",
              attrs: {
                width: "60%",
                dialogTitle: "知识库分类管理",
                "show-fullscreen": true,
              },
              on: {
                cancel: function ($event) {
                  _vm.showTreeDialog = false
                },
                confirm: _vm.handleTreeNodeSave,
              },
            },
            [
              _c("avue-form", {
                ref: "addForm",
                attrs: { option: _vm.treeDialogOption },
                on: { submit: _vm.treeNodeSave },
                model: {
                  value: _vm.form,
                  callback: function ($$v) {
                    _vm.form = $$v
                  },
                  expression: "form",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showDialog
        ? _c(
            "common-dialog",
            {
              attrs: {
                width: "60%",
                dialogTitle: "知识分类",
                "show-confirm-btn": _vm.formType != "view",
              },
              on: { cancel: _vm.closeDialog, confirm: _vm.handleNodeSave },
            },
            [
              _c("avue-form", {
                ref: "addFormList",
                attrs: { option: _vm.dialogOption },
                on: { submit: _vm.saveList },
                model: {
                  value: _vm.formList,
                  callback: function ($$v) {
                    _vm.formList = $$v
                  },
                  expression: "formList",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }